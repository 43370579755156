.print-root {
  font-family: HelveticaNeue;
  font-size: 14px;
}

.print-page {
  page-break-after: always;
}

.print-page-heading {
  font-size: 28px;
}

.print-heading {
  font-weight: bold;
}

.print-text {
  line-height: 1.5;
}

.print-divider {
  margin: 15px 0;
  border-bottom: 1px solid #8a8a8a;
}

.print-table {
  width: 100%;
}

.print-table thead {
  border-bottom: 1px solid #8a8a8a;
}

.print-table th {
  text-align: left;
  font-weight: bold;
  padding: 0 5px 10px;
}

.print-table td {
  padding: 2px 5px 0;
  vertical-align: middle;
}

.print-table th:first-child,
.print-table td:first-child {
  padding-left: 0px;
}

.print-table th:last-child,
.print-table td:last-child {
  padding-right: 0px;
}

.print-table tr:first-child td {
  padding-top: 10px;
}

.print-table-section {
  padding-top: 40px !important;
}

@media screen {
  .print-root {
    max-width: 800px;
    margin: 40px auto;
    color: #4a4a4a;
  }

  .print-page {
    margin-top: 150px;
  }

  .print-page:first-child {
    margin-top: 0px;
  }

  .print-divider {
    border-bottom-color: #aaa;
  }

  .print-table thead {
    border-bottom-color: #aaa;
  }

  .print-table-section {
    padding-top: 60px !important;
  }
}

@media print {
  .print-root {
    margin-top: 20px;
  }
}