html {
  height: 100%;
}

body {
  min-height: 100%;
  background-color: #fff !important;
  color: #444;
  font-size: 14px;
  font-family: 'HelveticaNeue';
  line-height: 1.43;
  font-weight: 400;
  overflow-y: scroll;
}

input,
button,
textarea {
  margin: 0;
  outline: none;
}

a,
button {
  text-decoration: none;
  color: inherit;
}

button {
  cursor: pointer;
  border: none;
  font-family: inherit;
  font-weight: inherit;
  font-size: inherit;
  background-color: transparent;
  padding: 0;
}

b {
  font-weight: bold;
}
